.settingsWrapper {
    flex: 1 1 auto;
    display: flex;
    height: calc(100% - 68px);
}
.settings {
    width: 100%;
    background-color: white;
    padding: 20px;
    overflow-y: auto;
}
.settings :global(.tab-content){
    height: 100%;
}
.settings :global(.nav-tabs){
    background-color: white;
    border-bottom: 1px solid black;
    padding-left: 30px;
    padding-right: 30px;
}
.settings :global(.nav-link.active){
    color: white;
    background-color: #59297b;
    border: 0;
}

.settings :global(.nav-item){
    border-radius: 0;
    color: white;
}
.settings :global(.nav-item:hover){
    background-color: #59297bc2;
    color:white;
}

.settings :global(.nav-link){
    border: 1px solid transparent;
    border-radius: 0;
    padding: 15px;
    color: #59297b;
}
.settings :global(.tab-pane){
    padding: 20px 30px;
}
.settings :global(.tab-content>.active){
    display: block;
    background-color: white;
    border-right: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    height: 100%;
}

.settings :global(h1) {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 1rem;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: inherit;
    color: #333;
    position: relative;
    font-weight: 500;
}

.settings :global(h2) {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: .875rem;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: inherit;
    color: #333;
    position: relative;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 5px;
}
.settingsInnerWrapper{
    max-width: 1300px;
}