.customForm {
    padding: 10px;
    background: white;
    border: 1px solid black;
    margin-left: 15px;
    margin-right: 15px;
}

.mailSection {
    padding-top: 10px;
    padding-bottom:30px;
    height: calc(100% - 70px);
    overflow: auto;
}

@media only screen and (max-width: 768px){
    .customContainer{
        padding: 0;
    }
    .customForm {
        margin: 0;
    }
    .sendButton {
        margin-top: 10px;
        width: 100%;
    }
}