.folderLabel {
    margin-left: 10px;
    vertical-align: text-top;
    cursor: pointer;
}
.folderLabel:hover {
    color: #0056b3;
}
.modalBody{
    max-height: calc(100VH - 180px);
    overflow: auto;
}