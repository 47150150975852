.emailList {
    border-right: 1px solid rgba(0, 0, 0, 0.0625);
    padding: 0;
    height: 100%;
    overflow-y: auto;
    min-width: 500px;
    /* width: 50%; */
    /* width: 100%; */
}

.emailList-item {
    cursor: pointer;
}

.emailList :global ul {
    padding: 0;
}

.emailList :global ul li {
    list-style: none;
    padding: 10px 15px 10px 0
}

.emailList :global ul li:hover {
    background-color: #f1f1f14f !important;
}

.emailList :global ul li a:hover {
    text-decoration: none;
}

.emailList :global h6, .emailList :global h5 {
    font-size: 14px;
    color: #7d7d7d;
    font-weight: 400;
}

.emailList :global h5 {
    font-weight: 500;
    color: #444;
}

.emailList :global small.email-date {
    max-width: 52px;
}

;
.emailList :global .mail-message {
    font-size: 14px;
    color: #444;
}

.emailList :global .nav-pills .nav-link {
    border-radius: 0px;
    padding-left: 40px;
}

.emailList :global .nav-pills .nav-link.active, .emailList :global .nav-pills .show>.nav-link {
    background-color: transparent;
}

.emailList :global .btn-group button {
    margin-right: 3px;
    width: 42px;
}

.emailList :global .form-control, .emailList :global .form-control:focus, .emailList :global .form-control:active {
    border: none;
    outline: none;
    box-shadow: none;
    line-height: 2.5;
}

.emailActionContainer {
    box-sizing: border-box;
    display: flex !important;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    height: auto;
    max-width: 100%;
    margin: 0;
    padding: 0 20px;
    background-color: #f8f9fa;
}

.emailAction {
    display: block;
    height: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-left: auto;
    margin-right: auto;
}

.emailAction :global .btn-group button:hover {
    background-color: #f5f1f8 !important;
}

.emailAction :global .btn-group button i.icon {
    color: #7d7d7d;
    font-size: 25px;
}

.actionImg {
    vertical-align: middle;
    border-style: none;
    max-width: 14px;
    top: -2px;
    position: relative;
}

.emailAction :global button img {
    vertical-align: middle;
    border-style: none;
    max-width: 20px;
    top: -2px;
    position: relative;
}

.email-content {
    padding: 0;
    background-color: #f1f1f1;
}

.moreAction {
    position: absolute;
    top: -7px;
    right: 110px;
}

.moreAction :global button.dropdown-toggle {
    z-index: 1;
}

.moreAction :global .dropdown-toggle::after {
    display: none;
}

.moreAction :global .dropdown-menu {
    min-width: 9rem;
}

.moreAction :global a.dropdown-item {
    padding: 4px 10px;
    font-size: 14px;
    color: #636363;
}

.moreAction :global a.dropdown-item:hover {
    background-color: #f5f1f8;
}

.moreAction :global img {
    max-width: 10px;
}

.email-content .tab-content img {
    vertical-align: middle;
    border-style: none;
    margin: 0 auto;
    position: relative;
    display: block;
    max-height: 430px;
}

.searchContainer {
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.0625);
    border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
}

.searchIcon {
    line-height: 1.5rem;
    margin-top: 15px;
    width: 10px;
    margin-right: 10px;
    font-size: 24px;
}

.searchWrapper {
    display: flex;
    padding-left: 30px;
}

.searchInput {
    border: none;
    outline: none;
    box-shadow: none;
    line-height: 2.5;
    height: 52px;
}

.styledCheckbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.mailItemSection :global(.row) {
    margin: 0;
}

.noMail {
    padding: 15px;
    text-align: center;
    font-size: 80%;
}

.mobileActionButton {
    height: 100%;
}

.showOnMobile {
    display: none;
}

.mailCol {
    padding: 0;
}

@media only screen and (min-width: 2200px) {
    .emailList {
        min-width: 1000px;
    }
}

@media (min-width: 2001px) and (max-width: 2200px) {
    .emailList {
        min-width: 900px;
    }
}

@media (min-width: 1701px) and (max-width: 2000px) {
    .emailList {
        min-width: 800px;
    }
}

@media (min-width: 1501px) and (max-width: 1700px) {
    .emailList {
        min-width: 700px;
    }
}

@media (min-width: 1301px) and (max-width: 1500px) {
    .emailList {
        min-width: 600px;
    }
}

@media (min-width: 1201px) and (max-width: 1300px) {
    .emailList {
        min-width: 500px;
    }
}

@media only screen and (max-width: 1200px) {
    .emailList {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .emailList {
        min-width: 100%;
    }
    .emailActionContainer {
        padding: 0;
    }
    .mailCol {
        padding: 0;
    }
    .searchWrapper {
        padding-left: 8px;
    }
}

@media only screen and (max-width: 450px) {
    .hideOnMobile {
        display: none !important;
    }
    .showOnMobile {
        display: block !important;
    }
    .dropdown :global(.dropdown-menu) {
        left: -100px;
    }
}