.sidebarMenu {
    /* height: calc(100VH - 65px); */
    border-right: 1px solid rgb(241, 241, 241);
    width: 250px;   
    min-width: 250px;
    overflow-y: auto; 
    overflow-x: hidden; 
}
.sidebarMenu :global .nav-link {
    padding: 10px 0px 10px 15px;
}

.sidebarMenu :global svg {
    margin-right: 20px;
}

.sidebarMenu :global .nav-link.active {
    background-color: #824aa914;
    border-radius: 0;
    color: #1f1f1f !important;
}

.sidebarMenu :global .nav-link:focus,
.sidebarMenu :global .nav-link:hover {
    text-decoration: none;
    background-color: #824aa914;
}

.sidebarMenu :global a {
    color: #969696 !important;
}

.sidebarMenu :global a p {
    position: relative;
    top: -4px;
    display: inline-block;
    margin-bottom: 0;
    margin-left: 10px;
}

.sidebarMenu :global form {
    padding: 10px 14px;
}

.sidebarMenu :global form .form-group {
    margin-bottom: 0px;
}

.sidebarMenu :global form .form-control:focus {
    border-color: #dc3545;
    outline: 0;
    box-shadow: none;
}

.sidebarMenu :global form input {
    font-size: 14px;
}

.sidebarMenu :global .folder {
    display: block;
    width: 180px;
    font-size: 14px !important;
    margin: 5px auto;
    color: #969ca9;
}

.sidebarMenu :global .nav-link.created_folder {
    display: block;
    position: relative;
}

.sidebarMenu :global .nav-link.created_folder button {
    float: right;
}

.sidebarMenu :global .nav-link.created_folder span.delete {
    float: right;
    position: absolute;
    right: 5px;
    top: 14px;
    display: none;
}

.sidebarMenu :global .nav-link.created_folder span.delete i {
    color: #dc3545;
}

.sidebarMenu :global .nav-item:hover {
    text-decoration: none;
    background-color: #824aa914;
}

  /**** START Large phone media only **/

  @media only screen and (max-width: 800px) {
    .sidebarMenu{
        width: 100%;
        left: -100%;
        transition: 1s;
        position:absolute;
        z-index:1000;
    }
    .sidebarMenu.mobileOpen{
        display: block !important;
        left: 0;
        height: calc(100VH - 67px);
    }

   
  }

  /**** END Large phone media only **/