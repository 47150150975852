.nav-item>div {
    padding: 10px 0px 10px 15px;
    color: #969696 !important;
    display: block;
    cursor: pointer;
}

.nav-item>div.active {
    text-decoration: none;
    background-color: #824aa914;
}