*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  overflow-y:hidden;
}

body,
html {
  font-size: 100%;
  padding: 0;
  margin: 0;
  background-color: #f0f0f0;  
}

html,
body {
  outline: 0;
  overflow-x: hidden;
}

#root {
  height: 100VH;
}

a:hover {
  text-decoration: none;
  color: #fff;
}

/* alert */
.alert-info {
  color: #59297b !important;
  background-color: #f5f1f8 !important;
  border-color: #7d58981f !important;
}

/* alignment */

.center {
  text-align: center !important;
}

.jc-sb {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.jc-fe {
  justify-content: flex-end;
}

.ai-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.fxw-nw {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: center;
}

/* border */

.bt-0 {
  border-top: 0;
}

.bd-r {
  border-right: 1px solid rgba(0, 0, 0, 0.0625);
}

.bd-b {
  border-bottom: 1px solid rgb(241, 241, 241);
}

.bdr-0 {
  border-radius: 0;
}

/* buttons */

.btn-transparent {
  background-color: transparent;
}

.btn-white {
  background-color: #fff !important;
}

.btn-red {
  color: #fff;
  background-color: #ed1b46 !important;
  border-color: #ed1b46 !important;
}

.btn-purple {
  color: #fff;
  background-color: #59297b !important;
  border-color: #59297b !important;
}

.btn-purple.lighter {
  color: #fff;
  background-color: #bb93d887 !important;
  border-color: #dbc6ea !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.purple {
  color: #59297b;
}

/* clearfix */

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

/* color */

.bg-grey-f1 {
  background-color: #f1f1f1;
}
.grey-500 {
  color: #505050 !important;
}

.grey-700 {
  color: #72777a !important;
}

.red {
  color: #ed1b46 !important;
}

.purple,
.purple-hover:hover {
  color: #59297b !important;
}

.bgc-white,
.bgcH-white:hover {
  background-color: #fff !important;
}

/* display */
.flex {
  display: flex;
}

.d-f,
.layers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fxg-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.layers {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}

/* font size */

.small {
  font-size: 12px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fw-300 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

/* height */
.fullvh {
  height: 100vh;
}

.h-100per {
  height: 100%;
}

.vh-80 {
  height: 80vh;
}

.h-300 {
  height: 300px;
}

.lh-38 {
  line-height: 38px !important;
}

.lh-35 {
  line-height: 35px !important;
}

.lh-50 {
  line-height: 50px !important;
}

.max-h-300 {
  max-height: 300px;
}

/* icon */
.step {
  display: inline-block;
  line-height: 1;
  position: relative;
  width: 10%;
}

.step i {
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.step:hover i {
  opacity: 0.3;
}

/* margins and padding */
.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-50 {
  margin-top: 50px;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px;
}

.ml--5 {
  margin-left: -5px !important;
}

.ml-0 {
  margin-left: 0px;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-43 {
  margin-bottom: 43px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mx-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}
.pt-25 {
  padding-top: 25px;
}
.pt-75 {
  padding-top: 75px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-130 {
  padding-top: 130px !important;
}

.pr-0 {
  padding-right: 0;
}

.pr-30 {
  padding-right: 30px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-30 {
  padding-left: 30px;
}

.pl-60 {
  padding-left: 60px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.px-170 {
  padding-left: 170px !important;
  padding-right: 170px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.p-0 {
  padding: 0;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

/* position */

.b-0 {
  bottom: 0;
}

.pos-a {
  position: absolute !important;
}

.pos-r {
  position: relative !important;
}

.jc-c {
  justify-content: center !important;
}

/* text */

.td-n {
  text-decoration: none !important;
}

.size-12 {
  font-size: 12px;
}
.size-14 {
  font-size: 14px;
}
.size-16 {
  font-size: 16px;
}
.size-18 {
  font-size: 18px;
}
.size-21 {
  font-size: 21px;
}
.size-24 {
  font-size: 24px;
}
.size-32 {
  font-size: 32px;
}
.size-48 {
  font-size: 48px;
}
.size-64 {
  font-size: 64px;
}
.size-96 {
  font-size: 96px;
}

/* overflow */

.ov-h {
  overflow: hidden !important;
}

/* ul style */
.lst-n {
  list-style-type: none;
}

/* width */

.w-93 {
  width: 93px;
}

.w-130 {
  width: 130px;
}

.w-135 {
  width: 135px;
}

.w-160 {
  width: 160px;
}

.w-200 {
  width: 200px;
}

.w-216 {
  width: 216px;
}

.w-50per {
  width: 50%;
}

.w-100per {
  width: 100%;
}

.max-w-200px {
  max-width: 200px;
}


/* Styles Checkbox */

/* input */

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 50px;
  margin-bottom: 0;
  margin-right: 10px;
}

/* Hide the browser's default checkbox */
.styled-checkbox input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0px;
  left: 25px;
  height: 15px;
  width: 15px;
  background-color: #e2e2e2;
}

/* On mouse-over, add a grey background color */
.styled-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.styled-checkbox input:checked ~ .checkmark {
  background-color: #59297b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.styled-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.styled-checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.full-width{
  width: 100%;
}

.dropzone-area{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  text-align: center;
  border: 2px dashed blue;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.btn-mail-action {
  background: rgba(255,255,255,.7);
}
.btn-mail-action:disabled {
  opacity: .2;
}
.form-control.StripeElement {
  padding-top: 9px;
}

._loading_overlay_wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.panel-primary {
  border-color: #428bca;
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.panel-success>.panel-heading {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: white;
}

.panel-info>.panel-heading {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: white;
}

.blank-page {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
}

.fade:not(.show) {
  opacity: 0;
  z-index: -1 !important;
}

a:hover {
  text-decoration: none;
  color: lightblue;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  opacity: .2;
}