.section {
    margin-top: 10px;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 10px 10px rgba(0, 0, 0, .05);
}

@media only screen and (max-width: 720px) {
    .section {
        margin-top: 0px;
        background-color: white;
        padding: 10px;
        border-radius: 0px;
        box-shadow: none;
    }
}