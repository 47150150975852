.panel {
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .3);
}

.panel:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4), 0 1px 5px rgba(130, 130, 130, 0.35);
}

.panelBody {
    padding: 0px;
    text-align: center;
}

.planBox{
    padding: 2px;
}

.thePrice {
    background-color: rgba(220, 220, 220, .17);
    box-shadow: 0 1px 0 #dcdcdc, inset 0 1px 0 #fff;
    padding: 20px;
    margin: 0;
}

.thePrice h1 {
    line-height: 1em;
    padding: 0;
    margin: 0;
}

.cnrflash {
    /*Position correctly within container*/
    position: absolute;
    top: -7px;
    right: -10px;
    z-index: 1;
    /*Set overflow to hidden, to mask inner square*/
    overflow: hidden;
    /*Set size and add subtle rounding  		to soften edges*/
    width: 100px;
    height: 100px;
    border-radius: 3px 5px 3px 0;
}

.cnrflashInner {
    /*Set position, make larger then 			container and rotate 45 degrees*/
    position: absolute;
    bottom: 0;
    right: 0;
    width: 145px;
    height: 145px;
    -ms-transform: rotate(45deg);
    /* IE 9 */
    -o-transform: rotate(45deg);
    /* Opera */
    -moz-transform: rotate(45deg);
    /* Firefox */
    -webkit-transform: rotate(45deg);
    /* Safari and Chrome */
    -webkit-transform-origin: 100% 100%;
    /*Purely decorative effects to add texture and stuff*/
    /* Safari and Chrome */
    -ms-transform-origin: 100% 100%;
    /* IE 9 */
    -o-transform-origin: 100% 100%;
    /* Opera */
    -moz-transform-origin: 100% 100%;
    /* Firefox */
    background-image: linear-gradient(90deg, transparent 50%, rgba(255, 255, 255, .1) 50%), linear-gradient(0deg, transparent 0%, rgba(1, 1, 1, .2) 50%);
    background-size: 4px, auto, auto, auto;
    background-color: #aa0101;
    box-shadow: 0 3px 3px 0 rgba(1, 1, 1, .5), 0 1px 0 0 rgba(1, 1, 1, .5), inset 0 -1px 8px 0 rgba(255, 255, 255, .3), inset 0 -1px 0 0 rgba(255, 255, 255, .2);
}

.cnrflashInner:before,
.cnrflashInner:after {
    /*Use the border triangle trick to make  				it look like the ribbon wraps round it's 				container*/
    content: " ";
    display: block;
    position: absolute;
    bottom: -16px;
    width: 0;
    height: 0;
    border: 8px solid #800000;
}

.cnrflashInner:before {
    left: 1px;
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.cnrflashInner:after {
    right: 0;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.cnrflashLabel {
    /*Make the label look nice*/
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    padding-bottom: 5px;
    color: #fff;
    text-shadow: 0 1px 1px rgba(1, 1, 1, .8);
    font-size: 13px;
    font-weight: bold;
    text-align: center;
}

.panelHeading {
    padding: 10px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    font-size: 18px;
}

.active {
    background-color: rgba(243, 225, 16, 0.27) !important;
}