/* Side Menu */

button.add-folder {
    display: none;
    border-radius: 0;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;
    background-color: #f8f9fa;
    color: #9c9c9c;
    outline: none;
    border: 0;
}

button.folder:hover {
    background-color: #f5f1f8 !important;
}
.folderWrapper{
    position: relative;
}
.folderButton {
    margin: 10px 40px;
    width: 170px;
}

.folderDelete {
    position: absolute;
    left: 7px;
    margin-top: 10px;
}

.folderDelete:hover {
    opacity: .3;
}
.addButton {
    display: none;
}

@media only screen and (max-width: 500px) {
    .folderButton {
        width: calc(100% - 20px);
        margin: 10px;
    }
    .cancelButton {
        width: calc(50% - 20px);
        margin: 10px;
    }
    .addButton {
        width: calc(50% - 20px);
        margin: 10px;
        display:inline-block;
    }
}