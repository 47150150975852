.wizard {
    min-height: 410px;
    background-color: #FFFFFF;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
    padding: 20px 0;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;

}

.wizard :global(.header) {
    text-align: center;
}

.wizard :global(.nav-pills > li) {
    width: 25%;
}


.wizard :global(.nav-pills > li.active div) {
    background-color: #ff9500;
    width: 100%;
}

.wizard :global(.nav-pills > li div) {
    text-align: center;
    display: block;
    padding: 5px;
    background-color: #999999;
    color: white;
    cursor: pointer;
}

.wizard :global(.info-text) {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
}

.wizard :global(.info-text small) {
    font-size: 15px;
}

.wizardFooter {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    padding: 10px;
}

.dropzone,
.dropzone div {
    outline: none !important;
}

.progressbar {
    margin: 70px 35px 0;
}

.idPreview {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.selectContainer {
    max-width: 380px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.idTypeChoiceButtons {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    padding: 10px;
}

.licenseImage {
    height: 180px;
    margin-bottom: 20px;
}

.deleteButton{
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    display:block;
    margin-top:5px;
}

@media only screen and (max-width: 900px) {
    .wizard {
        margin-top: 0 !important;
        min-height: calc(100VH - 68px) !important;
    }
    .wizardFooter {
        bottom: 50px;
    }

    .responsiveContainer {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0;

    }
}