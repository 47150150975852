.mailSection {
    padding-top: 10px;
    padding-bottom:30px;
    height: calc(100% - 70px);
    overflow: auto;
}
.forwardTitle {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 20px !important;
    font-weight: bold;
}


@media only screen and (max-width: 1200px) {
    .sericeContainer {
        max-width: 99%;
    }
}

