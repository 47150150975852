.previewWrapper {
    /* max-width: calc(100VW - 850px); */
    /* width: calc(100VW - 751px);
     */
     width:100%;
     background:white;
    padding:10px;
}

.pdfDocument{
    height: 100%;
    width:100%;
}

.pdfPage {
    overflow-y: auto;
    /* max-height: calc(100VH - 70px); */
    width: 100%;
    max-width: 100%;
    /* width: calc(100VW - 850px); */
}

.pdfPage canvas {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100VW - 1020px);
    max-height: calc(100VH - 70px);
    width: auto !important;
    height: auto !important;
}
.pdfPage > div {
    display: none;
}

.pagerSection {
    position: absolute;
    background-color: rgba(0,0,0,.3);
    color: white;
    right: 35px;
    z-index: 10000;
    padding: 5px;
    bottom: 10px;
}
.loading {
    background: white;
    width: 100%;
}
.loader { 
    width: calc(100VW - 850px);
}
.instructionsIcons {
    font-size: 50px;
    color: rgba(0,0,0,.3);
    display: block !important;
    text-align: center;
    width: 100% !important;
    margin-bottom: 10px;
}
.instructionWrapper{ 
    position: relative;
    height: 100%;
}
.instructions {
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    top: calc(50% - 210px);
    left: calc(50% - 208px);
    width: 420px;
    height: 420px !important;
    color: rgba(0,0,0,.7);
}
.instructionWrapper :is(img){
    max-width: 20px;
}

@media only screen and (max-width: 1200px) {
    .loading {
        display: none;
    }
    .previewWrapper { 
        display :none;
    }
}