.state :global(h1) {
    vertical-align: middle;
    line-height: 90px;
    font-weight: bold;
}

.addressLabel {
    margin-left: 10px;
}

.alert {
    margin-left: auto;
    margin-right: auto;
}
.saveButton {
    position: absolute;
    right: 15px;
    bottom: -50px;
    width: 200px;
    max-width: calc(50% - 20px);
}

.backButton {
    position: absolute;
    left: 15px;
    bottom: -50px;
    width: 200px;
    max-width: calc(50% - 20px);
}

.locations :global(.row:not(:first-child)) {
    margin-top: 10px;
}

.addressComment {
    font-size: 12px;
    max-width: 345px;
}
.addressCard {
    min-height: 133px;
}

@media only screen and (max-width: 991px) {
    .state :global(h1) {
        line-height: 10px;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .addressElementWrapper {
        margin-top: 10px;
    }
}
