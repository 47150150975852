.serviceContainer {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #e2dfda;
    margin-top: 10px;
}

.serviceContainer :global(.light-border) {
    background: #fff;
    border: 1px solid #f3f1ee;
    padding-top: 20px;
    padding-bottom: 20px;
}

:global(.light-border) a:hover {
    color: black;
}

.serviceContainer :global(h2) {
    font-size: 22px;
    font-weight: bold;
}

.serviceContainer :global(h3) {
    font-size: 23px;
    font-weight: bold;
}

.serviceContainer :global(p) {
    font-size: 15px;
}

.australiaPostBorder {
    border-top: 5px solid #ffd200;
}

.dhlBorder {
    border-top: 5px solid #d40511;
}

.subPrice {
    padding: 0;
    text-align: center;
}

.subPrice p {
    font-size: 12px;
}

.subPrice h3 {
    font-size: 18px;
}

.price p {
    font-size: 12px;
}

.priceContainer {
    width: 93px;
}

.serviceContainer {
    margin-left: 15px;
    margin-right: 15px;
}

.featureSection {
    margin-left: 15px;
    margin-right: 15px;
}

.forwardingLogo {
    margin-bottom: 10px;
    height: 30px;
}

@media only screen and (max-width: 768px) {
    .featureSection {
        margin-left: 0;
        margin-right: 0;
    }
    .estimateTime {
        margin-bottom: 10px;
    }
    .priceContainer {
        width: 100% !important;
    }
}

@media only screen and (max-width: 575px) {
    .price {
        text-align: center;
    }
    .subPrice p, .price p {
        margin-bottom: 0;
        margin-top: 10px;
    }
    .subPrice h3, .price h3 {
        width: 100%;
    }
    .sendButton {
        width: 100%;
    }
    .serviceContainer {
        margin: 10px 0 0 0 !important;
    }
}