.forwardWrapper {
}
.forwardList {
    padding: 0;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    background-color: white;
}

.forwardListItem {
    border-bottom: 1px solid rgb(241, 241, 241);
}

.sectionHeading {
    border-bottom: 1px solid rgb(241, 241, 241);
    font-weight: bold;
    background: #824aa914;
    padding-top: .5rem;
}

.forwardItem {
    padding: 10px;
    border-bottom: 1px dashed rgb(241, 241, 241);
    max-width: 1200px;
    margin: 0;
}

.checkSection {
    display: inline-block;
    vertical-align: top;
    margin-top: 24px;
    margin-left: 10px;
}

.dateSection {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    margin-top: 2px;
    min-width: 120px;
}
.detailSection {
    display: inline-block;
}
.detailSection:first-child, .detailSection:last-child {
    min-width: 150px;
}

.forwardDetails {
    vertical-align: top;
    margin-left: 15px;
    display: inline-block;
    min-width: 150px;
}

.toolbar {
    padding: 4px;
    width: 100%;
}

.toolbarButton {
    background: rgba(255,255,255,.9);
    margin-left: 10px;
}

@media only screen and (max-width: 768px) {
    .logRow {
        padding: 0 !important;
    }
}