.paymentWrapper {
    margin: 20px 0 20px;
}
.creditCardImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 100%;
}
.paymentDetailsTable {
    width: 435px;
    margin-left: auto; 
    margin-right: auto;
    margin-top: 15px;
}

.paymentDetailsTable td {
    vertical-align: middle;
}

.paymentDetailsTable td {
    padding: .75rem .3rem
}
.autoTopUpSelect {
    width: calc(100% - 30px);
    display: inline-block;
    margin-right: 7px;
}

@media only screen and (max-width: 990px) {
    .paymentDetailsTable {
      width: 100%;
    }
  }
  