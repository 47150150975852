.address {}

.address img {
    max-width: 50px;
    margin-right: 10px;
}

.address ul {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    margin: 0 auto;
    padding: 0;
    max-width: 1200px;
    list-style: none;
    -ms-box-orient: horizontal;
    -ms-box-pack: center;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
}

.address ul li {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0;
    text-align: center;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 20px;
}

.address :global(.tab-current span) {
    color: grey;
}

.address :global(span) {
    font-size: 25px;
    color: #ed1b46;
    font-weight: bold;
    vertical-align: middle;
    text-transform: uppercase;
}

.address :global(ul li.tab-current) {
    background-color: white;
}

.forwardBody {
    background-color: white;
}

.tab {
    max-width: 1050px;
    width: 100%;
}

.addressBody {
    padding: 20px;
}

.newAddress {
    text-align: center;
    padding: 20px 0;
    width: 120px;
    color: #ed1b46;
}

.newAddressTitle {
    font-size: 1rem;
    font-weight: 100;
}

.sentWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sentImg {
    position: absolute;
    max-width: 100px;
    top: -40px;
    padding: 4px;
    border: 4px solid #e2e2e2;
    border-radius: 50% !important;
    left: 50%;
    transform: translate(-50%, 0);
}

.sentWrapper {
    text-align: center;
    margin-top: 20px;
}

.errorContainer {
    margin-top: -100px;
    position: relative;
    display: block;
    height: 420px;
    margin: 0 auto;
    background-color: #fff;
    padding: 45px 20px;
    width: 370px;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-bottom: 5px solid #eee;
    top: -80px;
}

.sentContainer {
    margin-top: -100px;
    position: relative;
    display: block;
    height: 370px;
    margin: 0 auto;
    background-color: #fff;
    padding: 45px;
    width: 370px;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    border-bottom: 5px solid #eee;
    top: -100px;
}

.disabled {
    opacity: .2;
    cursor: not-allowed !important;
}

.addressModal :global(.form-group),.addressModal :global(.row) {
    margin-bottom: 10px;
}

.addressModal :global(label){
    margin-bottom: 0;
}

@media only screen and (max-width: 404px) {
    .backSection {
        position: absolute;
        top: 95px;
        z-index: 100;
    }
    .address {
        display: block !important;
    }
    .addressModal :global(.modal-dialog) {
        margin: 0;
    }
}