.supportWrapper {
    background-color: white;
    height: calc(100VH - 50px);
    overflow: auto;
    padding: 30px;
}
.supportIcon{
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    display: block;
    margin-top: 50px;
}
.message{
    height: 150px !important;
}