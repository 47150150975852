.saveButton {
        position: absolute;
        right: 15px;
        bottom: -50px;
        width: 200px;
}

.settingForm {
        max-width: 1300px;
}

.settingWrapper :global(.form-label) {
        margin-top: 8px;
        margin-bottom: 0;
}

.settingWrapper :global(.form-group) {
        margin-bottom: 0px;
}

.emailInput {
        width: calc(100% - 120px);
        display: inline-block;
        margin-right: 10px;
}

.emailVerify {
        width: 110px;
        display: inline-block;
        vertical-align: baseline;
}

.languageSelect{
        border: none;
        background: #f6f6f6;
}

@media only screen and (max-width: 799px) {
        .saveButton {
                width: 100%;
                bottom: 0;
                position: inherit;
                left: 0;
                margin: 0;
        }
}