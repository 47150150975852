.mailListItem {
    border-bottom: 1px solid rgb(241, 241, 241);
    list-style: none;
    cursor: pointer;
}

.saving {
    opacity: .2;
}

/* .mailListItem:hover {
    background-color: rgba(0,0,0,.05);
} */

.peers {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    height: auto;
    max-width: 100%;
    margin: 0;
    margin-right: 5px;
    padding: 0;
    width: 100%;
}

.peer {
    display: block;
    height: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 50px;
    margin-bottom: 0;
    margin-right: 10px;
}

.peerGreed {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.moreAction {
    position: absolute;
    top: -7px;
    right: 110px;
}

.moreAction img {
    max-width: 10px;
}

.moreAction :global a.dropdown-item:hover {
    background-color: #f5f1f8;
}

.moreAction :global a.dropdown-item {
    padding: 4px 10px;
    font-size: 14px;
    color: #636363;
}

.moreAction :global .dropdown-menu {
    min-width: 9rem;
}

.moreActionToggle {
    z-index: 1;
}

.moreActionToggle::after {
    display: none;
}

.mailListItem a:not(:global(.dropdown-item)) {
    color: #32647f;
    padding-left: 0;
    padding-right: 0;
}

.mailListItem :global(.dropdown-item) {
    color: rgba(0, 0, 0, .6);
}

.mailStatus {
    font-weight: 100;
    color: #bbb;
    width: 125px;
    text-align: right;
    white-space: nowrap;
}

.editNameButton {
    font-size: 13px;
    color: #bbb !important;
}

small.emailDate {
    max-width: 52px;
}

.emailList h5 {
    font-weight: 500;
    color: #444;
}

.mailMessage {
    font-size: 14px;
    color: #444;
}

.StatusIcon img {
    max-width: 20px;
}

.checkmark {
    position: absolute;
    top: 0px;
    left: 25px;
    height: 15px;
    width: 15px;
    background-color: #e2e2e2;
}

.checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.mailWrapper small {
    margin-left: 5px;
}

.nameInput {
    width: calc(100% - 45px);
    margin-right: 10px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.nameSpan {
    width: calc(100% - 45px);
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    background: transparent;
}

.mailStatusLabel {
    float: right;
    font-size: 10px;
    opacity: 0.9;
    color: #32647f !important;
}

.mailItemCheck {
    width: 30px;
    margin: 0 !important;
}

.dropdown {
    display: inline-block;
}

.mailItem {
    padding-right: 0;
}

.saveNameButton {
    display: inline-block;
    width: 10px !important;
    margin-left: -5px;
    cursor: pointer
}

.unread {
    font-weight: bold;
}

.mailActive {
    background-color: rgba(0, 0, 0, .03);
}

.contextButton {
    position: absolute;
    right: 5px;
    top: -15px;
}
.contextButton::after{
    content: "⋮";
    border: none !important;
    font-weight: bold;
    font-size: 24px;
    position: absolute;
    right: 5px;
    top: -10px;
}
.mailId {
    float:left;
    font-size:12px;
    margin-left: 31px;
}
.mailIdSmallScreen{
    display:none;
}
@media only screen and (max-width: 360px){
    .mailIdSmallScreen{
        display:none !important;
    }
}
@media only screen and (max-width: 991px) {
    .mailWrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 1300px) {
    .mailIdSmallScreen {
        display: inline-block !important;   
        margin-left: 4px; 
    }
    .mailId{
        display:none;
    }
    .mailStatusLabel {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .nameLine {
        width: 100%;
    }
    .mailStatus {
        text-align: left;
    }
}

@media only screen and (max-width: 600px) {
    .mailItem {
        padding: 0;
    }
}

@media only screen and (max-width: 450px){
    .contextButton {
        position: absolute;
        right: -10px;
    }
}