.navbar {
  padding: 0 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  background-color: #fff;
  flex: 0 1 auto;
}

.navbarBrand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbarBrand img {
  vertical-align: middle;
  border-style: none;
  height: 65px;
}

.navbar p {
  margin: 0;
  color: #000;
  font-size: 16px;
  text-align: right;
  font-weight: 500;
}

.navbar p span {
  font-weight: 300;
  font-size: 14px;
}

.mobileMenuButton {
  height: 65px;
  display: none;
}

.dropdown {
  display: inline-block;
}

/* client icon */

.clientIcon {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  height: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.clientIcon:after {
  display: none;
}

a.dropdown-toggle.clientIcon::after {
  display: none;
}

.dropdown.client .dropdown-menu.show {
  display: block;
  top: 48px;
  min-width: 11rem;
}

.dropdown-menu .divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  height: 1px;
  overflow: hidden;
}

.clientIcon .peer {
  display: block;
  height: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.clientIcon .peer>img {
  max-width: none;
  border-radius: 50% !important;
  width: 2rem;
}

.alert {
  margin-bottom: 0;
  color: black;
  font-weight: bold;
  cursor: pointer;
}

/**** END NO MEDIA AIMING *****/

/**** START Large phone media only **/

@media only screen and (max-width: 1100px) {
  /* On mobile phones alert are displayed over the top of the nav bar at the top of screen */
  .alert {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 40px 10px 10px;
    border-bottom: 1px solid black;
    border-radius: 0;
    text-align: center;
    min-height: 66px;
  }
  .alert :global .close {
    height: 100%;
  }
}

.supportIcon {
  display: inline-block;
  margin-right: 20px;
  font-size: 30px;
  vertical-align: bottom;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  /* Nav bars should take up less realestate */
  .navbar {
    padding: 0 15px;
  }
  .mobileMenuButton {
    display: block;
  }
  .navbarBrand {
    display: none;
  }
}

@media only screen and (max-width: 450px){
  .hideOnMobile{
    display: none !important;
  }
  .dropdown :global(.dropdown-menu){
    left: -100px;
  }
}

/**** END Large phone media only **/